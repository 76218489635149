<template src="./projectDetails.html"></template>

<script>
export default {
  data() {
    return {
      PDFDataProject: "",
    };
  },
  created: function() {
    const vm = this;
    vm.download();
  },
  methods: {
    download() {
      const vm = this;
      vm.axios.get(vm.$store.state.filooServer + "clinic-project/pdf/download/" + vm.$session.get("clinicProjectId"), {
        headers: { Authorization: vm.$session.get("bearer") },
      })
        .then(function(response) {
          if (response.status === 200) {
            vm.PDFDataProject = response.data.URL;
          }
        });
    },
  },
};
</script>

<style lang="scss" src="./projectDetails.scss">
</style>
